<template>
  <span>
    <i class="fas fa-plane mr-1 text-gray-400 w-5 text-sm"></i>
    <span class="font-semibold text-sm">
      <slot>Vluchtnummer</slot>:
    </span>
    <span v-if="!vlucht || vlucht.length <= 1" class="text-red-500 font-bold">Geen vluchtnummer</span>
    <div v-else class="inline-flex flex-row gap-2 justify-center">
      <ExternalLink
        :href="`https://www.google.com/search?q=${vlucht}+flight+status`"
        class="hover:underline"
      >
        {{ vlucht.toUpperCase() }}
      </ExternalLink>
      <span v-if="luchthavenLinks?.length" class="inline-flex flex-row gap-1.5">
        <ExternalLink
          v-for="(item, index) in luchthavenLinks"
          :key="`${index}-${item.label}`"
          :href="item.link"
          class="group hover:underline bg-gray-600 rounded text-xs text-white font-bold px-2 py-0.5 scale-50 text-center"
        >
          <i :class="item.icon || 'fa fa-plane'" class="hover:underline group-hover:underline"></i>
          <span v-if="item.label" class="ml-1">{{ item.label }}</span>
        </ExternalLink>
      </span>
    </div>
  </span>
</template>

<script setup>
import { computed } from 'vue'
import { isLuchthaven } from '@taxiboeken-v2/utilities-berekening'

import ExternalLink from '@/components/UI/ExternalLink.vue'

const props = defineProps({
  vlucht: String,
  adressen: Array,
})

const luchthavenLinks = computed(() => {
  const vlucht = String(props.vlucht || '').toUpperCase()
  const result = []

  if (!props.adressen.length) return

  const ophaalAdres = props.adressen[0]
  const bestemming = props.adressen[props.adressen.length - 1]

  const isBestemmingZaventem = isLuchthaven(bestemming) && bestemming?.locality === 'Zaventem'
  const isZaventemOphaal = isLuchthaven(ophaalAdres) && ophaalAdres?.locality === 'Zaventem'

  if (isBestemmingZaventem && !isLuchthaven(ophaalAdres)) {
    result.push({ label: 'BRU', link: `https://www.brusselsairport.be/nl/passengers/flights/vertrek?search=${vlucht}` })
  } else if (isZaventemOphaal) {
    result.push({ label: 'BRU', link: `https://www.brusselsairport.be/nl/passengers/flights/arriving?search=${vlucht}` })
  }

  result.unshift({
    icon: 'fab fa-google',
    link: `https://www.google.com/search?q=${vlucht}+flight+status`,
  })

  return result
})
</script>
